exports.components = {
  "component---src-pages-10-years-js": () => import("./../../../src/pages/10-years.js" /* webpackChunkName: "component---src-pages-10-years-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-alla-hjartans-dag-js": () => import("./../../../src/pages/alla-hjartans-dag.js" /* webpackChunkName: "component---src-pages-alla-hjartans-dag-js" */),
  "component---src-pages-christmas-new-year-opening-hours-js": () => import("./../../../src/pages/christmas-new-year-opening-hours.js" /* webpackChunkName: "component---src-pages-christmas-new-year-opening-hours-js" */),
  "component---src-pages-fathers-day-js": () => import("./../../../src/pages/fathers-day.js" /* webpackChunkName: "component---src-pages-fathers-day-js" */),
  "component---src-pages-find-us-js": () => import("./../../../src/pages/find-us.js" /* webpackChunkName: "component---src-pages-find-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lunch-js": () => import("./../../../src/pages/lunch.js" /* webpackChunkName: "component---src-pages-lunch-js" */),
  "component---src-pages-menu-tsx": () => import("./../../../src/pages/menu.tsx" /* webpackChunkName: "component---src-pages-menu-tsx" */),
  "component---src-pages-mors-dag-js": () => import("./../../../src/pages/mors-dag.js" /* webpackChunkName: "component---src-pages-mors-dag-js" */),
  "component---src-pages-new-years-js": () => import("./../../../src/pages/new-years.js" /* webpackChunkName: "component---src-pages-new-years-js" */),
  "component---src-pages-opening-hours-js": () => import("./../../../src/pages/opening-hours.js" /* webpackChunkName: "component---src-pages-opening-hours-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

